
import PageMainHeading from "@/components/Layout/PageMainHeading.vue";
import Card from "@/components/ui/Card/Card.vue";
import SearchControl from "@/components/Form/SearchControl.vue";
import Country from "@/components/ui/Country/Country.vue";
import SvgIcon from "@/components/ui/SvgIcon.vue";
import { computed, defineComponent, ref, Ref, watch } from "vue";
import { mapGetters, useStore } from "vuex";
import Partner from "@/components/ui/Partner.vue";
import { ICarrier } from "@/store/carriers";
import { networkGenerationsToString } from "@/store/modules/carriers/utills/networkGenerationsToString";
import AppButton from "@/components/ui/Buttons/AppButton.vue";
import BtnContent from "@/components/ui/Buttons/BtnContent.vue";
import AppPreloader from "@/components/ui/AppPreloader.vue";
import CustomScrollbar from "@/components/ui/CustomScrollbar.vue";
import { IUpdateCarrierDto } from "@/api/services/packages/esim/carriers/types";
import {
  INetworkGeneration,
  IPlanShort
} from "@/api/services/packages/esim/location-packages/types";
import UpdateCarrierModal from "@/components/Packages/eSIM/Carriers/UpdateCarrierModal.vue";
import DeleteConfirmDialog from "@/components/ui/Modal/Dialog/instances/DeleteConfirmDialog.vue";

const initialEditCarrierData: IUpdateCarrierDto = {
  country: -1,
  image: "",
  title: "",
  netGens: [],
  active: false
};

export default defineComponent({
  name: "Carriers",
  components: {
    DeleteConfirmDialog,
    UpdateCarrierModal,
    CustomScrollbar,
    BtnContent,
    AppButton,
    Partner,
    AppPreloader,

    SvgIcon,
    Country,
    SearchControl,
    PageMainHeading,
    Card
  },

  setup() {
    const store = useStore();
    const selectedCountryId: Ref<number | null> = ref(null);
    const carriers: Ref<ICarrier[]> = ref([]);
    const carriersLoading = ref(false);
    const countriesLoading = ref(false);
    const searchQuery = ref("");
    const carrirsCountries = computed(() => {
      return store.getters.getCountries;
    });

    watch(
      () => selectedCountryId,
      async countryId => {
        if (countryId.value) {
          carriersLoading.value = true;

          const carriersList = await store.dispatch("getCarriers", {
            countryId: selectedCountryId.value
          });

          carriers.value = carriersList.map((carrier: ICarrier) => {
            const filteredNetGens = carrier.netGens.filter(ng => {
              return ng.active;
            });
            return {
              ...carrier,
              networkGenerationString: networkGenerationsToString(
                filteredNetGens
              )
            };
          });

          carriersLoading.value = false;
        }
      },
      { deep: true }
    );

    async function fetchCarriersCountries() {
      countriesLoading.value = true;
      selectedCountryId.value = null;
      const { success, message } = await store.dispatch("fetchCountries", {
        filter: searchQuery.value
      });

      if (!success && message) {
        await store.dispatch("showErrorNotification", message);
      }

      countriesLoading.value = false;
    }

    watch(() => searchQuery, fetchCarriersCountries, { deep: true });
    fetchCarriersCountries();

    return {
      countries: carrirsCountries,
      selectedCountryId,
      carriers: carriers,
      carriersLoading,
      searchQuery,
      countriesLoading,
      fetchCountries: fetchCarriersCountries,
      searchHandler(evt: InputEvent) {
        const { target } = evt;
        searchQuery.value = (target as HTMLInputElement).value
          .toLowerCase()
          .trim();
      },

      selectCountry(id: number) {
        // reset selected country
        if (selectedCountryId.value === id) {
          selectedCountryId.value = null;
          return;
        }

        // select new country
        selectedCountryId.value = id;
      }
    };
  },

  data() {
    return {
      currentNetworkGenerations: [],
      editCarrierData: { ...initialEditCarrierData },
      updateCarrierModalOpened: false,
      deleteConfirmDialogOpened: false,
      isEditing: false,
      updateLoading: false,
      deleteLoading: false,
      deleteCarrierName: "",
      deleteCarrierId: -1
    } as {
      currentNetworkGenerations: INetworkGeneration[];
      editCarrierData: IUpdateCarrierDto;
      updateCarrierModalOpened: boolean;
      deleteConfirmDialogOpened: boolean;
      isEditing: boolean;
      updateLoading: boolean;
      deleteLoading: boolean;
      deleteCarrierName: string;
      deleteCarrierId: number;
    };
  },

  computed: {
    ...mapGetters({
      allNetworkGenerations: "networkGenerations"
    })
  },

  async created() {
    await this.$store.dispatch("fetchNetworkGenerations");
    const storeNetworkGenerations = this.$store.getters.networkGenerations;
    console.log(storeNetworkGenerations);
    initialEditCarrierData.netGens = storeNetworkGenerations.map(
      ({ id, key, title, active }: INetworkGeneration) => {
        return {
          id: -1 + id,
          active,
          networkGeneration: { id, title, active, key }
        };
      }
    );
  },

  methods: {
    deleteCarrier(carrierId: number) {
      const currentCarrier: ICarrier | null =
        this.carriers.find(({ id }) => {
          return id === carrierId;
        }) || null;

      if (currentCarrier) {
        this.deleteCarrierName = currentCarrier.name;
        this.deleteCarrierId = currentCarrier.id;
        this.deleteConfirmDialogOpened = true;
      }
    },

    editCarrier(carrierId: number) {
      const currentCarrier: ICarrier | null =
        this.carriers.find(({ id }) => {
          return id === carrierId;
        }) || null;

      if (currentCarrier) {
        this.editCarrierData = {
          title: currentCarrier.name,
          image: currentCarrier.image,
          country: currentCarrier.countryId,
          netGens: currentCarrier.netGens,
          active: currentCarrier.active,
          id: currentCarrier.id
        };

        this.isEditing = true;
        this.updateCarrierModalOpened = true;
      } else {
        this.$store.dispatch("showErrorNotification", "Something wrong");
      }
    },

    createCarrier() {
      this.isEditing = false;

      this.editCarrierData = {
        ...initialEditCarrierData,
        country: this.selectedCountryId ?? -1
      };

      this.updateCarrierModalOpened = true;
    },

    discardUpdateCarrier() {
      this.updateCarrierModalOpened = false;

      if (this.isEditing) {
        this.editCarrierData = {
          ...initialEditCarrierData,
          country: this.selectedCountryId ?? -1
        };
      }
    },

    deleteCancel() {
      this.deleteCarrierId = -1;
      this.deleteCarrierName = "";
      this.deleteConfirmDialogOpened = false;
    },

    async deleteConfirm() {
      this.deleteLoading = true;

      const { success, message, status } = await this.$store.dispatch(
        "deleteCarriers",
        this.deleteCarrierId
      );

      const _success = success || status.code === -1;

      this.deleteLoading = false;
      this.deleteConfirmDialogOpened = false;

      if (!_success && message) {
        await this.$store.dispatch("showErrorNotification", message);

        return;
      }

      if (_success) {
        this.$store.commit("setCarriers", {
          countryId: this.selectedCountryId,
          carriers: []
        });

        const id = this.selectedCountryId;

        await this.fetchCountries();

        await this.$nextTick(() => {
          this.selectedCountryId = id;
        });
      }
    },

    async submitHandler(data: IUpdateCarrierDto) {
      this.updateLoading = true;
      const { success, message } = await this.$store.dispatch(
        "updateCarriers",
        {
          ...{
            ...data,
            country: this.selectedCountryId,
            id: this.editCarrierData.id || -1
          },
          isCreate: !this.isEditing
        }
      );

      if (!success && message) {
        await this.$store.dispatch("showErrorNotification", message);
      }

      this.updateLoading = false;

      if (success) {
        this.$store.commit("setCarriers", {
          countryId: this.selectedCountryId,
          carriers: []
        });

        const id = this.selectedCountryId;

        this.updateCarrierModalOpened = false;
        await this.fetchCountries();

        this.selectedCountryId = null;

        await this.$nextTick(() => {
          this.selectedCountryId = id;
        });

        await this.$store.dispatch("clearRatesStore");
      }
    }
  }
});
